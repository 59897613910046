<mat-toolbar class="!bg-slate-800 py-8">
  <a
    class="mx-auto block"
    data-test="logo"
    routerLink="/"
  >
    <img
      class="brand w-40 cursor-pointer text-xl font-semibold"
      src="../../../assets/logo.png"
    />
  </a>
</mat-toolbar>
<router-outlet></router-outlet>

import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'codenteam-guest',
  templateUrl: 'guest.component.html',
  standalone: true,
  imports: [MatToolbarModule, RouterModule, MatIconModule],
})
export class GuestComponent {
  constructor() {}
}
